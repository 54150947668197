<template>
    <div>
        <b-col>
            <b-row>
                <b-col cols="12" md="3" class="p-0 p-3" style="position: relative;height: calc(100vh - 56px);overflow: scroll;">
                    <b-form-select 
                        required 
                        @change="kontrol($event)" 
                        id="input-1" 
                        class="mb-3"
                        v-model="secilen_bolge"
                        :options="bolgeler"></b-form-select>

                    <div v-if="dene.length > 0">
                        <draggable v-model="dene" @end="onEnd">
                            <transition-group>
                                <b-list-group-item class="hover" v-for="konteyner in dene" :key="konteyner.kk_id" style="background-color: #FFF;">
                                    <b-badge variant="primary" class="hover" @click="ortala(konteyner.kk_id)">
                                        <b-icon-map class="mr-2"></b-icon-map>ID: {{konteyner.kk_id}}
                                    </b-badge>
                                    <b-badge class="ml-1">
                                        Order: {{konteyner.order}}
                                    </b-badge>
                                    <b-badge @click="listeyedenSil(konteyner)" variant="danger" class="hover float-right"><b-icon-trash></b-icon-trash></b-badge>
                                </b-list-group-item>
                            </transition-group>
                        </draggable>

                        <b-button @click="kaydet" block variant="primary" class="mt-3">Kaydet</b-button>
                    </div>

                    <div v-else>
                        <div v-if="secilen_bolge">
                            <p>Bu bölgeye ait herhangi bir konteyner bulunamadı.</p>
                        </div>
                        <div v-else>
                            <p>Bölge seçmediniz!</p>
                        </div>
                    </div>
                </b-col>
                
                <b-col cols="12" md="9" class="p-0">
                    <gmap-map
                        :center="center"
                        :zoom="zoom"
                        style="width:100%;  height: calc(100vh - 56px);"
                        
                        :options="options"
                        @click="tikla">
                        <gmap-marker
                            v-for="(m, index) in tum_konum_konteynerlar"
                            :key="index"
                            :position="m"
                            :icon="m.kk_bolge != 0 ? dots.red : dots.green"
                            @click="() => {
                                tikla();
                                m.info = true;    
                            }">
                            <gmap-info-window
                                :options="infoOptions"
                                :position="infoPosition"
                                :opened="m.info"
                                :content="infoContent"
                                @closeclick="m.info=false">
                                <div style="width: 200px;">
                                    <div> 
                                        <p style="font-weight: 700;font-size: 15px;">
                                            ID: <b-badge class="calloutTitle">#{{m.kk_id}}</b-badge>
                                        </p>
                                    </div>
                                    <div style="margin-top: 5px;margin-bottom: 15px;font-size: 14px;"> 
                                        <p>Adres: {{m.kk_adres}}</p>
                                    </div>
                                    <b-button v-if="m.kk_bolge == 0" @click="listeyeEkle(m)" variant="primary" size="sm" class="mr-2">Rotaya Ekle</b-button>
                                    <b-button v-if="m.kk_bolge != 0" @click="listeyedenSil(m)" variant="danger" size="sm" class="float-right">Rotadan Çıkart</b-button>
                                </div>
                            </gmap-info-window>
                        </gmap-marker>
                        <gmap-polyline
                            :options="{
                                strokeColor: '#2a55d0',
                                strokeWidth: 3
                            }"
                            :path="dene"></gmap-polyline>
                    </gmap-map>
                </b-col>
            </b-row>
        </b-col>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import Swal from 'sweetalert2';

export default {
    data(){
        return{
            center: {
                lat: 38.4174357,
                lng: 27.1560596
            },
            markers: [],
            zoom: 13,
            secilen_bolge: null,
            dene: [],
            dots: {
                green: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                red: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            },

            infoPosition: null,
            infoContent: null,
            infoOpened: true,
            infoCurrentKey: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: 0
                }
      
            },
            tum_konum_konteynerlar: [],
            options:{
                disableDefaultUI:false,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            { visibility: "off" }
                        ]
                    }
                ]
            }
        }
    },

    mounted(){
        this.tum_konumlar();
    },

    methods: {
        tum_konumlar(){
            this.tum_konum_konteynerlar = this._tum_konum_konteynerlar.filter(c => c.kk_bolge == 0 || c.kk_bolge == this.secilen_bolge);
        },

        tikla(){
            // const yeni = {
            //     lat: Number(e.latLng.lat()),
            //     lng: Number(e.latLng.lng())
            // }
            for(var i=0; i<this.tum_konum_konteynerlar.length; i++){
                this.tum_konum_konteynerlar[i].info = false;
            }
            //this.tum_konum_konteynerlar.push(yeni);
        },

        addMarker(e){
            console.log(e)
        },

        sil(e){
            //this.markers.splice(e, 1)
            console.log(e)
            this.$store.dispatch("konteynerEdit", {
                kk_id: e.kk_id,
                kk_bolge: 0,
            })
            var index = this.dene.findIndex(c => c.kk_id == e.kk_id);
            if(index > -1){
                this.dene.splice(index, 1)
            }
        },

        kontrol(){
            
        },

        ortala(kk_id){
            var ortalamaVeriIndex = this.konum_konteynerlar.findIndex(c => c.kk_id == kk_id);
            if(ortalamaVeriIndex > -1){
                this.center = {
                    lat: Number(this.konum_konteynerlar[ortalamaVeriIndex].lat),
                    lng: Number(this.konum_konteynerlar[ortalamaVeriIndex].lng),
                }
                this.zoom = 16
            }
        },

        onEnd(e){
            this.dene[e.oldIndex].order = e.oldIndex;
            this.dene[e.newIndex].order = e.newIndex;
        },
        
        kaydet(){
            if(this.secilen_bolge){
                var popup = Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Lütfen Bekleyin',
                    showConfirmButton: false,
                });
                var deger = this.dene.map(c => {
                    console.log({
                        kk_id: c.kk_id,
                        kk_bolge: this.secilen_bolge,
                        kk_order: c.order,
                    })
                    return {
                        kk_id: c.kk_id,
                        kk_bolge: this.secilen_bolge,
                        kk_order: c.order,
                    }
                })

                deger.forEach(d => {
                    this.$store.dispatch("konteynerEdit", d);    
                });
                popup.close();
            }else{
                Swal.fire({
                    title: "Bölge Seçmediniz!",
                    icon: "error",
                    text: "Bölge seçmediğiniz için verileriniz kaydedilemedi!",
                    confirmButtonText: 'Tamam'
                });
            }
        },

        listeyeEkle(item){
            if(this.secilen_bolge){
                var indexFull = this.tum_konum_konteynerlar.findIndex(r => r.kk_id == item.kk_id);
                if(indexFull > -1){
                    this.tum_konum_konteynerlar[indexFull].kk_bolge = this.secilen_bolge;
                }
                var index = this.dene.findIndex(c => c.kk_id == item.kk_id);
                if(index > -1){
                    Swal.fire({
                        title: "Zaten Ekli!",
                        icon: "error",
                        text: "Seçtiğiniz konum bölgede zaten ekli!",
                        confirmButtonText: 'Tamam'
                    });
                }else{
                    item.order = this.dene.length;
                    this.dene.push(item);
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Bölge Seçimi Yapmadınız!",
                    text: "Seçtiğiniz konumu bir bölgeye atayabilmek için önce bölge seçmelisiniz!"
                });
            }
        },

        listeyedenSil(item){
            if(this.secilen_bolge){
                var indexFull = this.tum_konum_konteynerlar.findIndex(r => r.kk_id == item.kk_id);
                if(indexFull > -1){
                    this.tum_konum_konteynerlar[indexFull].kk_bolge = 0;
                }

                var index = this.dene.findIndex(c => c.kk_id == item.kk_id);
                if(index > -1){
                    this.dene.splice(index, 1)
                }
                this.$store.dispatch("konteynerEdit", {
                    kk_id: item.kk_id,
                    kk_bolge: 0,
                })
                
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Bölge Seçimi Yapmadınız!",
                    text: "Seçtiğiniz konumu bir bölgeye atayabilmek için önce bölge seçmelisiniz!"
                });
            }
        },
    },

    computed: {
        bolgeler(){
            var data = [];
            data.push({
                value: null,
                text: "Bölge Seç"
            })
            this.$store.state.bolgeler.map(c =>{
                data.push({
                    value: c.bolge_id,
                    text: c.bolge_ad
                })
            });
            return data;
        },

        _tum_konum_konteynerlar(){
            return this.$store.state.konteynerlar.map(c => {
                return {
                    lat: Number(c.kk_lat),
                    lng: Number(c.kk_lng),
                    kk_id: c.kk_id,
                    kk_bolge: c.kk_bolge,
                    order: c.kk_order,
                    info: false,
                    kk_adres: c.kk_adres,
                }
            });
        },

        konum_konteynerlar(){
            var deger = [];
            if(this.secilen_bolge){
                deger = this.$store.state.konteynerlar.filter(r => r.kk_bolge == this.secilen_bolge).map(c => {
                    return {
                        lat: Number(c.kk_lat),
                        lng: Number(c.kk_lng),
                        kk_id: c.kk_id,
                        order: c.kk_order,
                        kk_bolge: c.kk_bolge,
                        info: false,
                    };
                });   
            }
            return deger;
        },

        polyline(){
            return this.dene.map(c => {
                return {
                    lat: c.lat,
                    lng: c.lng,
                }
            });
        }
    },

    components: { draggable },

    watch: {
        konum_konteynerlar(){
            this.dene = this.konum_konteynerlar.map((c,index) => {
                return {
                    lat: Number(c.lat),
                    lng: Number(c.lng),
                    kk_id: c.kk_id,
                    order: index,
                    kk_bolge: c.kk_bolge,
                }
            });
        },

        _tum_konum_konteynerlar(){
            this.tum_konumlar();
        },

        secilen_bolge(){
            this.tum_konumlar();
        }
    }
}
</script>


<style scoped>
.hover:hover{
    cursor: pointer;
}
.calloutTitle{
    position: relative;
    top: -1px;
}
</style>